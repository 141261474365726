<template>
  <ValidationObserver ref="dealExtraInfoForm">
    <card class="shadow" body-classes="standard-card-body">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label-width="labelWidth" :label="$t('fields.contract_amount')">
          <template slot="value">
            <label-theme-component v-if="readOnly">
              {{ dealExtraInfoLocal.contract_amount | euro }}
            </label-theme-component>
            <ValidationProvider
              v-else
              class="flex-grow-1"
              name="contract_amount"
              rules="min_value:0"
              v-slot="{ passed, failed, errors }"
            >
              <base-input class="mb-0"
                          type="number"
                          v-model="dealExtraInfoLocal.contract_amount"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              />
            </ValidationProvider>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.payment_type')">
          <template slot="value">
            <label-theme-component v-if="readOnly && dealExtraInfoLocal.payment_type">
              {{ $t('deal.payment_types.' + dealExtraInfoLocal.payment_type) }}
            </label-theme-component>
            <base-input v-else class="mb-0 flex-grow-1">
              <el-select
                clearable
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="dealExtraInfoLocal.payment_type"
              >
                <el-option
                  v-for="(option, index) in paymentOptions"
                  v-bind:key="`payment-type-${index}`"
                  class="select-default text-uppercase"
                  :value="option"
                  :label="$t('deal.payment_types.' + option)"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.installment_plan')">
          <template slot="value">
            <label-theme-component v-if="readOnly && dealExtraInfoLocal.installment_plan">
              {{ dealExtraInfoLocal.installment_plan }}
            </label-theme-component>
            <base-input v-else class="mb-0 flex-grow-1">
              <el-select
                clearable
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="dealExtraInfoLocal.installment_plan"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(option, index) in installmentPlans"
                  v-bind:key="`installment-plan-${index}`"
                  :value="option"
                  :label="option === '1' ? $t('deal.single_installment') : `${option} ${$t('deal.installments')}`"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.enrollment_amount')">
          <template slot="value">
            <label-theme-component v-if="readOnly">
              {{ dealExtraInfoLocal.enrollment_amount | euro }}
            </label-theme-component>
            <ValidationProvider
              v-else
              class="flex-grow-1"
              name="enrollment_amount"
              rules="min_value:0"
              v-slot="{ passed, failed, errors }"
            >
              <base-input class="mb-0"
                          type="number"
                          v-model="dealExtraInfoLocal.enrollment_amount"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              />
            </ValidationProvider>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.enrollment_payment_type')">
          <template slot="value">
            <label-theme-component v-if="readOnly && dealExtraInfoLocal.enrollment_payment_type">
              {{ $t('deal.payment_types.' + dealExtraInfoLocal.enrollment_payment_type) }}
            </label-theme-component>
            <base-input v-else class="mb-0 flex-grow-1">
              <el-select
                clearable
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
                v-model="dealExtraInfoLocal.enrollment_payment_type"
              >
                <el-option
                  class="select-default text-uppercase"
                  v-for="(option, index) in paymentOptions"
                  v-bind:key="`enrollment-payment-type-${index}`"
                  :value="option"
                  :label="$t('deal.payment_types.' + option)"
                >
                </el-option>
              </el-select>
            </base-input>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.withheld_fee')">
          <template slot="value">
            <div class="d-flex justify-content-between align-items-center">
              <label-theme-component class="text-uppercase" v-if="readOnly">
                {{ dealExtraInfoLocal.withheld_fee ? $t('common.yes') : $t('common.no') }}
              </label-theme-component>
              <el-switch
                v-else
                class="ml-auto text-uppercase"
                v-model="dealExtraInfoLocal.withheld_fee"
              />
            </div>
          </template>
        </list-group-item-component>
      </ul>
    </card>
    <card class="shadow" body-classes="standard-card-body">
      <ul class="list-group list-group-flush">
        <list-group-item-component :label-width="labelWidth" :label="$t('fields.km_traveled')">
          <template slot="value">
            <div class="valueClass" v-if="readOnly">{{ dealExtraInfoLocal.km_traveled }} Km</div>
            <ValidationProvider
              v-else
              class="flex-grow-1"
              name="km_traveled"
              rules="min_value:0"
              v-slot="{ passed, failed, errors }"
            >
              <base-input class="mb-0"
                          type="number"
                          v-model="dealExtraInfoLocal.km_traveled"
                          :error="errors[0]"
                          :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
              />
            </ValidationProvider>
          </template>
        </list-group-item-component>

        <list-group-item-component :label-width="labelWidth" :label="$t('fields.consulting_in_presence')">
          <template slot="value">
            <div class="d-flex justify-content-between align-items-center">
              <label-theme-component class="text-uppercase" v-if="readOnly">
                {{ dealExtraInfoLocal.consulting_in_presence ? $t('common.yes') : $t('common.no') }}
              </label-theme-component>
              <el-switch
                v-else
                class="ml-auto text-uppercase"
                v-model="dealExtraInfoLocal.consulting_in_presence"
              />
            </div>
          </template>
        </list-group-item-component>
      </ul>
    </card>
  </ValidationObserver>
</template>

<script>
import {Select, Option, Checkbox, Switch} from 'element-ui';
import DealExtraInfo from "@/models/dealExtraInfo";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import DealPaymentTypes from "../resources/dealPaymentTypes";

export default {
  name: "DealExtraInfoForm",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    [Option.name]: Option,
    [Select.name]: Select,
    [Checkbox.name]: Checkbox,
    [Switch.name]: Switch,
  },
  data() {
    return {
      dealExtraInfoLocal: this.$_.cloneDeep(DealExtraInfo),
      paymentOptions: [
        DealPaymentTypes.financing_interest.value,
        DealPaymentTypes.financing_interest_free.value,
        DealPaymentTypes.transfer.value,
        DealPaymentTypes.credit_card.value,
        DealPaymentTypes.debit_card.value,
        DealPaymentTypes.cash.value,
        DealPaymentTypes.soisy.value,
        DealPaymentTypes.scalapay.value,
        DealPaymentTypes.secura.value,
      ],
      installmentPlans: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '18', '24', '30'],
      labelWidth: 230
    }
  },
  props: {
    dealExtraInfo: {
      type: Object,
      default: () => DealExtraInfo
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.$_.isEmpty(this.dealExtraInfo)) {
      this.dealExtraInfoLocal = this.$_.cloneDeep(this.dealExtraInfo);
    }

    this.dealExtraInfoLocal.installment_plan = this.dealExtraInfoLocal.installment_plan || '1';
  },
  methods: {
    getDealExtraInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.dealExtraInfoForm.validate()
          .then((success) => {
            if (success) {
              resolve(this.dealExtraInfoLocal);
            } else {
              this.$notify({
                type: 'danger',
                message: this.$t('notifications.cannot_update_deal_with_invalid_contract_data')
              });
              reject();
            }
          })
          .catch((err) => {
            reject(err)
          });
      });
    },

    validateDealExtraInfo() {
      return new Promise((resolve, reject) => {
        // let isValid = true;

        // this.$_.each(this.dealExtraInfo, (item, key) => {
        //   if (key !== 'confirmed_date' && !item && item !== false && item !== 0) {
        //     isValid = false;
        //   }
        // });

        let isValid = this.dealExtraInfoLocal.contract_amount
          && this.dealExtraInfoLocal.payment_type
          && this.dealExtraInfoLocal.installment_plan;

        if(this.dealExtraInfoLocal.enrollment_amount) {
          isValid = isValid && this.dealExtraInfoLocal.enrollment_payment_type
        }

        if (!isValid) {
          this.$notify({
            type: 'danger',
            message: this.$t('notifications.cannot_update_deal_with_invalid_contract_data')
          });
          reject();
        }

        resolve();
      })
    }
  }
}
</script>

<style scoped>

</style>
