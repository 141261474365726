<template>
  <div class="container-fluid">
    <div class="row">
      <octo-header-button @onSaveDeal="saveDeal" :buttons="headerButtons"/>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h2>
          {{ this.registry |optional('surname') }}
          {{ this.registry |optional('name') }}
        </h2>
      </div>
      <div class="col-md-8">
        <tabs
          tabNavWrapperClasses="col-md-2"
          tabContentClasses="col-md-10"
          vertical
          square
          class="row"
        >
          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('deal.deal') }}</div>
                  <octo-icon slot="reference" icon="euro"/>
                </el-popover>
              </span>
            <deal-status-container
              :deal="deal"
              :key="`dealStatusContainer-${updateAfterMountedKey}`"
            />
            <deal-summary
              :read-only="false"
              :deal="deal"
              show-deal-dates
              ref="dealSummary"
              :allowed-status="[
                this.dealStatuses.recall.value,
                this.dealStatuses.not_interested.value,
                this.dealStatuses.not_valid.value
                ]"
              :key="`summary-${updateAfterMountedKey}`"
            />
          </tab-pane>

          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('common.personal_data') }}</div>
                  <octo-icon slot="reference" icon="personal-data"/>
                </el-popover>
              </span>
            <registry-personal-data
              save-self
              :registry="registry"
              :key="`data-${updateAfterMountedKey}`"
            />
            <octo-entity-addresses
              ref="octoEntityAddresses"
              save-self
              :addresses="registry.addresses"
              :registry-id="registry.id"
              :key="`addresses-${updateAfterMountedKey}`"
            />
          </tab-pane>
          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('common.contact_details') }}</div>
                  <octo-icon slot="reference" icon="contact"/>
                </el-popover>
              </span>
            <octo-entity-emails
              save-self
              :emails="registry.emails"
              :registry-id="registry.id"
              :key="`emails-${updateAfterMountedKey}`"
            />
            <octo-entity-phones
              save-self
              :phones="registry.phones"
              :registry-id="registry.id"
              :key="`phones-${updateAfterMountedKey}`"
            />
          </tab-pane>

          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('fields.courses') }}</div>
                  <octo-icon slot="reference" icon="academic"/>
                </el-popover>
              </span>
            <deal-course-picker
              read-only
              ref="dealCoursePicker"
              :deal-details="dealCourses"
              :key="`courses-${updateAfterMountedKey}`"
            />
            <deal-extra-info-form
              read-only
              ref="dealExtraInfoForm"
              :deal-extra-info="deal.dealExtraInfo"
              :key="`deal-datum-form-${updateAfterMountedKey}`"
            />
          </tab-pane>

          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('callcenter.history_lead') }}</div>
                  <octo-icon slot="reference" icon="history"/>
                </el-popover>
              </span>
            <lead-history :lead-id="deal | optional('lead.id')" :key="`leadHistory-${updateAfterMountedKey}`"/>
          </tab-pane>

          <tab-pane>
              <span slot="label">
                <el-popover trigger="hover" placement="right">
                  <div class="text-uppercase">{{ $t('callcenter.history_registry') }}</div>
                  <octo-icon slot="reference" icon="history"/>
                </el-popover>
              </span>
            <registry-history :registry-id="registry.id" :key="`registryHistory-${updateAfterMountedKey}`"/>
          </tab-pane>
        </tabs>
      </div>
      <div class="col-md-4">
        <deal-summary-general
          enable-change-operator
          enable-change-sale
          :deal="deal"/>
        <deal-history :deal="deal" :key="`dealHistory-${updateAfterMountedKey}`"/>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import OctoEntityEmails from "@/components/octo-entity-emails/OctoEntityEmails";
import OctoEntityAddresses from "@/components/octo-entity-addresses/OctoEntityAddresses";
import OctoEntityPhones from "@/components/octo-entity-phones/OctoEntityPhones";
import Deal from "@/models/deal";
import Registry from "@/models/registry";
import RegistryPersonalData from "../Registries/components/RegistryPersonalData";
import DealHistory from "@/pages/Deals/components/DealHistory";
import DealStatusContainer from "@/pages/Deals/components/DealStatusContainer";
import DealSummary from "@/pages/Deals/components/DealSummary";
import DealStatuses from "@/pages/Deals/resources/dealStatuses";
import DealSummaryGeneral from "@/pages/Deals/components/DealSummaryGeneral";
import DealCoursePicker from "@/pages/Deals/components/DealCoursePicker";
import DealExtraInfoForm from "@/pages/Deals/components/DealExtraInfoForm";
import DealDocuments from "@/pages/Deals/components/DealDocuments";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import RegistryHistory from "@/pages/Registries/components/RegistryHistory";
import LeadHistoryCall from "@/pages/CallCenter/OperatorComponents/LeadHistoryCall";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";

export default {
  name: "ShowDealExpiredPage",
  components: {
    LeadHistoryCall,
    OctoHeaderButton,
    DealDocuments,
    DealExtraInfoForm,
    DealCoursePicker,
    DealSummaryGeneral,
    DealSummary,
    DealStatusContainer,
    DealHistory,
    RegistryPersonalData,
    OctoEntityPhones,
    OctoEntityAddresses,
    OctoEntityEmails,
    OctoIcon,
    Tabs,
    TabPane,
    RegistryHistory,
    LeadHistory
  },
  data() {
    return {
      endpoints: endpoints,
      deal: this.$_.cloneDeep(Deal),
      registry: this.$_.cloneDeep(Registry),
      updateAfterMountedKey: 1,
      dealStatuses: DealStatuses,
      lockedByStatus: false,
      headerButtons: [{
        label: 'save',
        onClick: 'onSaveDeal',
        confirmRequired: true,
        confirmTitle: this.$t('confirm.save_deal_expired_title')
      }],
      saleDate: {},
    }
  },
  beforeMount() {
    this.$fullLoading.show();
    this.$api.get(endpoints.DEAL_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
        this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
        this.updateAfterMountedKey++;
      })
      .catch(() => {
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        this.$router.push({name: "deals.archive"});
      })
      .finally(() => {
          this.$fullLoading.hide();
        }
      );
  },
  computed: {
    dealCourses() {
      return this.$_.filter(this.deal?.deal_details || [], detail => {
        return detail.detailable_type === 'Course';
      })
    },
  },
  methods: {

    saveDeal: async function () {
      try {
        const newStatus = this.$refs.dealSummary.getStatus();
        const newSubStatus = await this.$refs.dealSummary.getSubStatus();

        this.$fullLoading.show();

        const resp = await this.$api.put(endpoints.DEAL_EXPIRED_UPDATE.replace('{id}', this.deal.id), {
          id: this.deal.id,
          user_id: this.deal.user_id,
          operator_id: this.deal.lead?.user_id || null,
          status: newStatus ? newStatus : this.deal.status,
          sub_status: newStatus ? newSubStatus : this.deal.sub_status,
          text: this.deal.text
        });

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        if (newStatus === this.dealStatuses.not_valid.value
          || newStatus === this.dealStatuses.not_interested.value) {
          await this.$router.push({name: 'deals.expired'});
        }

        this.deal = resp?.data?.data || this.$_.cloneDeep(Deal);
        this.registry = this.deal?.registry || this.$_.cloneDeep(Registry);
        this.updateAfterMountedKey++;

      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    }
  }
}
</script>

<style scoped>

</style>
